import Btn from '@/component/button/btn/btn';
import Link_btn from '@/component/link/link_btn/link_btn';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { FC, memo, ReactNode } from 'react';

export interface I_Not_found_content {
  title?: ReactNode | string;
  body?: ReactNode | string;
}

const Not_found_content: FC<I_Not_found_content> = memo<I_Not_found_content>((props: I_Not_found_content) => {
  const { title, body } = props;
  const { t } = useTranslation();
  const { back } = useRouter();

  return (
    <div className="c gap-3 py-5 text-center" data-testid="Not_found_content">
      <h1>404</h1>
      <div className="capitalize">🤷‍♂️ {title || t('common:content_not_found')} 🤷‍♀️</div>
      <div>
        <Link_btn href="/" variant="text">
          {t('router:home')}
        </Link_btn>
        <Btn variant="text" onClick={back}>
          {t('router:back')}
        </Btn>
      </div>
      {body}
    </div>
  );
});

export default Not_found_content;
