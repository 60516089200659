import Layout_public from '@/component/layout/layout_public/layout_public';
import Not_found_content from '@/component/not_found_content/not_found_content';
import { T_component_with_page_layout } from '@/declaration/type/t_component_with_page_layout';
import { fn_get_static_props_common_ns } from '@/state/page/fn_get_static_props_common_ns';
import { title_build } from '@/utility/browser/title_build';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import React, { HTMLAttributes, memo } from 'react';

export interface I_Not_found extends HTMLAttributes<HTMLDivElement> {}

const Not_found: T_component_with_page_layout<I_Not_found> = memo<I_Not_found>(() => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{title_build([t('common:content_not_found')])}</title>
      </Head>
      <div className="c gap-4">
        <Not_found_content />
      </div>
    </>
  );
});

Not_found.get_layout = (page) => <Layout_public>{page}</Layout_public>;

export default Not_found;

export const getStaticProps: GetStaticProps<any, any> = fn_get_static_props_common_ns();
